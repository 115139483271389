/*
* ===================================================
*    CUSTOMIZE DEFAULT BOOTSTRAP 5 COMPONENTS
* ===================================================
*/

/*
   NAVBAR
   ------------------------
*/
.navbar-light {

    .navbar-nav {
        .nav-link {
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: $font-size-base * 0.9;
            padding-left: ($nav-link-padding-x);
            padding-right: ($nav-link-padding-x);
            padding-top: $nav-link-padding-y * 1.5;
            padding-bottom: $nav-link-padding-y * 1.5;
            letter-spacing: 0.1em;


            @include media-breakpoint-up(lg) {
                border-top: 0.3rem solid transparent;
                padding: ($nav-link-padding-y * 3) ($nav-link-padding-x);
            }

            &.active, &:focus {
                background: $primary;
                border-color: darken($primary, 10%);
                color: $white;
            }

            &:hover:not(.active):not(:focus) {
                background: rgba($primary, .5);
                border-color: rgba($primary, .8);
            }

            &::after {
                margin-left: 0.5rem;
            }
        }
    }


    // NAVBAR LIGHT
    &.nav-light {
        .navbar-nav {
            .nav-link {
                &:hover:not(.active):not(:focus) {
                    background: none;
                    border-color: rgba($primary, .8);
                }

                &::after {
                    margin-left: 0.5rem;
                }
            }
        }
    }

}

.navbar-collapse {
    @include media-breakpoint-down(md) {
        max-height: 600px;
        overflow-y: scroll;
    }
}

.navbar-toggler {
    &:focus {
        outline: none;
    }
}


/*
   DROPDOWNS
   ------------------------
*/
.dropdown-menu {
    @include media-breakpoint-up(lg) {
        box-shadow: $box-shadow-sm;
        &[data-bs-popper] {
            margin-top: -1px;
        }
    }
}

.dropdown-item {
    transition: all 0.3s;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &:hover, &:focus {
        padding-left: $spacer + 0.1rem;
    }
}


/*
   NAV PILLS
   ------------------------
*/
.nav-pills {
  .nav-link {
    &:hover:not(.active) {
        background: $gray-200;
    }
  }
}


/*
   ACCORDION
   ------------------------
*/
.accordion-btn {
    @include font-size(inherit);
}

.accordion-item:not(:first-of-type) {
    border: $accordion-border-width solid $accordion-border-color;
}

/*
   BUTTONS
   ------------------------
*/
.btn {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.btn-link {
    font-weight: $btn-font-weight;
}

.btn:not(.btn-light):not(.btn-outline-light):not(.btn-link) {
    &:hover {
        color: $white;
    }
}

.btn:not(.btn-light):not(.btn-outline-light):not(.btn-link):not([class*='-outline-']) {
    color: $white;
}

.btn-outline-light {
    &:hover {
        color: $primary;
    }
}


/*
   FORMS
   ------------------------
*/
.form-check-label {
    font-size: $form-check-label-font-size;
}

.form-control {
    &::placeholder {
        font-size: 0.85rem;
        font-weight: $font-weight-light;
    }
}
