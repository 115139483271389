/*
*
* ==========================================
* SWIPER SLIDER
* ==========================================
*
*/
.swiper-pagination-bars {
    .swiper-pagination-bullet {
        width: 3.5rem;
        height: 4px;
        border-radius: 0;
        margin: 0 !important;
        background: #ccc;

        &-active {
            background: $primary;
        }
    }
}

.swiper-pagination-light {
    .swiper-pagination-bullet {
        background: $gray-400;
        opacity: 1;

        &-active {
            background: #ffffff;
        }
    }
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    &-active {
        background: $primary;
    }
}

.swiper-button-next,
.swiper-button-prev {
    top: 0;
    width: 3rem;
    &::after {
        font-size: 1.7rem;
        font-weight: bold;
        color: $primary;
    }

    &.swiper-button-disabled {
        opacity: 1;
        &::after {
            color: #ccc;
        }
    }
}

.swiper-button-prev {
    left: 0;
}

.swiper-button-next {
    right: 0;
}

.swiper-slide-thumb {
    cursor: pointer;
    border: 5px solid transparent;
    transition: all 0.3s;
    opacity: 0.7;

    &-active {
        border-color: rgba($primary, 0.5);
        opacity: 1;
    }
}



/*
* ==========================================================
*     Maps
* ==========================================================
*/
.map-custom-popup {

    .leaflet-popup-content-wrapper {
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        background: transparent;
        font-family: $font-family-base;
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .leaflet-popup-content {
        margin: 0;
        background: #fff;
    }

    .leaflet-popup-tip {
        background: #fff;
        box-shadow: none;
    }

    a.leaflet-popup-close-button {
        display: inline-block;
        width: 30px !important;
        height: 30px !important;
        padding: 0 !important;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        color: #fff !important;
        background: $dark !important;
        font-weight: bold;
        line-height: 30px !important;
    }

    .leaflet-popup-content {
        p {
            margin: 0 0 1rem;
        }
    }

    .popup-venue {
        display: flex;

        .image {
            width: 200px;
            background-position: center center;
            background-size: cover;
        }

        .text {
            width: 400px;
            padding: 1rem;
        }
    }

  }

  .map-custom-tooltip {
    padding: .5rem;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #fff;
    font-family: $font-family-base;
    font-weight: bold;

    &.active {
        color: #fff;
        border-color: $primary;
        background-color: $primary;

        &:before {
            border-top-color: $primary;
        }
    }
  }



/*
* ==========================================================
*     CUSTOM SELECT [CHOICES.JS]
* ==========================================================
*/



.choices__list--dropdown {
    border: 1px solid $gray-200 !important;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08) !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.choices__item {
    transition: background 0.3s !important;
    overflow-wrap: anywhere !important;
}

.choices__list--dropdown .choices__item--selectable {
    padding-right: 1rem !important;
}

.choices__inner {
    min-height: auto !important
}



/*
* ==========================================================
*     404 SVG ILLUSTRATOR
* ==========================================================
*/
.st9{fill:lighten($primary, 20%) !important;}
.st10{fill:lighten($primary, 37%) !important;}
.st11{fill:none;stroke: lighten($primary, 35%) !important;stroke-miterlimit:10;}
.st12{fill:none;stroke: lighten($primary, 35%) !important;stroke-miterlimit:10;stroke-dasharray:4.0148,4.0148;}
.st13{fill:lighten($primary, 40%) !important;}
.st14{fill:lighten($primary, 25%) !important;}
.st15{fill:none;stroke: lighten($primary, 35%) !important;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st16{fill:#27282d !important;}
.st18{fill:none;stroke:#676767 !important;stroke-width:0.5;stroke-miterlimit:10;}
.st19{fill:lighten($primary, 35%) !important;}
.st20{fill:none;stroke:lighten($primary, 18%) !important;stroke-width:0.5;stroke-miterlimit:10;}
.st21{fill:none;stroke:lighten($primary, 18%) !important;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st26{fill:#5b5b5b !important;}
.st29{fill:lighten($primary, 35%) !important;}
.st30{fill:#4a4a4a !important;}
.st31{fill:none;stroke:lighten($primary, 18%) !important;stroke-width:0.5;stroke-miterlimit:10;}
.st32{font-family: serif !important}
