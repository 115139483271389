/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/

body {
    overflow-x: hidden;
    &.boxed {
        background: url("../img/boxed-pattern.png");
    }
}

strong {
    font-weight: $font-weight-bold;
}

#all {
    &.boxed {
        max-width: 1340px;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0 0 5px #ccc;

        .container {
            @include media-breakpoint-up(lg) {
                padding: 0 2.5rem;
            }
        }
    }
}


/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/
.nav-holder {
    z-index: 999;
}

.menu-large {
    position: static !important;

    .megamenu {
        @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: map-get($container-max-widths, xxl);
            left: 50%;
            transform: translateX(-50%);
            margin-top: -1px;
        }
    }
}

.nav-link-sub {
    color: $dropdown-color;
    text-decoration: none;
    transition: all 0.3s;
    display: block;
    border-bottom: 1px solid #eee;
    font-size: 0.75rem;
    &:hover, &:focus {
        color: $primary;
        transform: translateX(0.3rem);
        text-decoration: none;
    }
    &.active {
        color: $primary;
    }
}

.make-sticky.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}


.dropdown-submenu {
    .dropdown-menu {
        top: 0 !important;
        left: 100%;
    }
}

.dropdown-submenu-toggle::after {
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}


/*
*
* ==========================================
* SHOP COMPONENTS
* ==========================================
*
*/
.product {
    $p: &;

    border-bottom: 1px solid $gray-300;
    transition: all 0.3s;
    position: relative;
    &-image {
        overflow: hidden;
        max-height: 20rem;
        img { transition: all 0.3s; }
    }

    &:hover {
        border-color: $black;
        #{$p}-image {
            img { transform: scale(1.1); }
        }
    }
}

.ribbon-holder {
    position: absolute;
    top: 1rem;
    left: -1rem;
}

.color-box {
    width: 0.9rem;
    height: 0.9rem;
    border: 1px solid $gray-200;
    display: block;
    margin-top: 2px;

    & + span {
        margin-top: 2px;
    }
}



/*
*
* ==========================================
* MAP ELEMENTS
* ==========================================
*
*/
#contactMap {
    height: 300px;
}
