/*
* ===================================================
*    CUSTOM BOOTSTRAPIOUS UTILS
* ===================================================
*/

/*
   TEXT UTILS
   ------------------------
*/

.text-uppercase {
    @include uppercase;
    letter-spacing: .1em;
}

.text-sm {
    font-size: $font-size-sm * 1.2 !important;
}

.text-xs {
    font-size: $font-size-sm !important;
}

.text-xl {
    @include media-breakpoint-up(lg) {
        font-size: 3.5rem !important;
    }
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-shadow {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
    text-shadow: 2px 2px 2px rgba($primary, .1);
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-inherit {
    color: inherit;
}

@each $color,
$value in $theme-colors {
    .text-#{$color}-dense {
        color: darken($value, 10%);
    }
}


/*
    Avatars
   ------------------------
*/

.avatar {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: $border-color;
    border-radius: 50%;
    background: $white;
    box-shadow: $box-shadow;
    line-height: 3rem;
    max-width: 100%;

    &.avatar-xs {
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
    }

    &.avatar-sm {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
    }

    &.avatar-md {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
    }    

    &.avatar-lg {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
    }

    &.avatar-xl {
        width: 7rem;
        height: 7rem;
        line-height: 7rem;
    }


    &.avatar-xxl {
        width: 14rem;
        min-width: 14rem;
        height: 14rem;
        line-height: 14rem;
    }

    &.avatar-border-white {
        border: solid 2px $white;
    }
}

.avatar-stacked {
    margin-left: -.5rem;
}



/*
    ROUNDED CORNERS - LG, SM
   ------------------------
*/

.rounded-lg {
    border-radius: $border-radius-lg;
}

.rounded-sm {
    border-radius: $border-radius-sm;
}

.rounded-xl {
    border-radius: 10rem;
}

/*
    RESPONSIVE BORDERS - BORDER-[BREAKPOINT]
   ------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix} {
            border: $border-width solid $border-color !important;
        }
    }
}

/*
    OPACITY HELPERS - .OPACITY-[1-9]
    ------------------------
*/


$opacity: 1 2 3 4 5 6 7 8 9 10;

@each $current-opacity in $opacity {
    .opacity-#{$current-opacity} {
        opacity: #{$current-opacity/10};
    }
}

/*
    Z-INDEX HELPERS - .Z-INDEX-[10-50]
    ------------------------
*/

$z-index: 10 20 30 40 50;

@each $current-z-index in $z-index {
    .z-index-#{$current-z-index} {
        z-index: #{$current-z-index};
    }
}

/*
     LETTER SPACING HELPERS - .LETTER-SPACING-[0-5]
    ------------------------
*/

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

$spacing: .1em .2em .3em .4em .5em;

@each $current-spacing in $spacing {
    $i: index($spacing, $current-spacing);

    .letter-spacing-#{$i} {
        letter-spacing: #{$current-spacing};
    }
}

/*
     COLOURS AND BACKGROUNDS
    ------------------------
*/


@each $color,
$value in $theme-colors {
    .text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    a.text-hover-#{$color} {
        transition: $transition-base;

        &:focus,
        &:hover {
            color: $value !important;
        }
    }

    .bg-#{$color}-light {
        background-color: theme-color-level($color, -11);
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $grays {
    .bg-gray-#{$color} {
        background: $value !important;
    }

    .text-gray-#{$color} {
        color: $value;
    }

    .border-gray-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $theme-colors {
    .badge-#{$color}-light {
        color: $value;
        background-color: theme-color-level($color, -11);
    }
}

/*
     TRANSPARENT OVERLAYS
    ------------------------
*/

.overlay-content {
    position: relative;
    z-index: 20;
}

.overlay-dense {
    &::after {
        opacity: 0.9 !important;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                background: #fff;
            }
        }
    }

    .overlay-hover-light#{$infix} {
        @include media-breakpoint-up($next) {
            &::after {
                transition: $transition-fade;
                opacity: 0;
            }
        }
    }

    .light-overlay#{$infix},
    .overlay-hover-light#{$infix}:hover {
        @include media-breakpoint-up($next) {
            &::after {
                opacity: .4;
            }
        }
    }

    .light-overlay#{$infix}-0,
    .overlay-hover-light#{$infix}-0:hover {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }

    .dark-overlay#{$infix} {
        position: relative;

        @include media-breakpoint-up($next) {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: ' ';
                opacity: .4;
                background: #000;
            }
        }
    }

    .dark-overlay#{$infix}-0 {
        @include media-breakpoint-up($next) {
            &::after {
                display: none;
            }
        }
    }
}

@each $color,
$value in $theme-colors {
    .#{$color}-overlay {
        position: relative;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: ' ';
            opacity: .4;
            background: $value;
            transition: all 0.3s;
        }
    }
}


/*
     OTHER
    ------------------------
*/

.overflow-visible {
    overflow: visible !important;
}

.shadow-0 {
    box-shadow: none !important;
}

.img-grayscale {
    transition: all 0.3s;
    filter: grayscale(100%);

    &:hover {
        filter: none;
    }
}

.reset-link {
    color: inherit;
    transition: all 0.3s;

    &:hover, &:focus {
        text-decoration: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.tag {
    font-size: 0.8rem;
    text-transform: uppercase;
    border: 1px solid $gray-300;
    transition: all 0.3s;
    letter-spacing: 0.1em;
    font-weight: $font-weight-bold;
    padding: .375rem 0.75rem;
    display: block;

    &:hover, &:focus {
        border-color: $primary;
        text-decoration: none;
    }
}


/*
     BACKGROUNDS
    ------------------------
*/

.bg-none {
    background: none !important;
}

.bg-pentagon {
    background: url("../img/texture-bw.png") center center repeat;
}

.bg-fixed {
    background-attachment: fixed !important
}

.bg-cover {
    background-size: cover !important;
}

.bg-center {
    background-position: center center !important;
}


/*
     ICONS
    ------------------------
*/
.icon-outlined {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    border-radius: 50%;
    border: 2px solid #fff;
    transition: all 0.3s;

    &.icon-lg {
        font-size: 2rem;
    }
    &.icon-sm {
        width: 55px;
        height: 55px;
        font-size: 1.15rem;
    }
    &.icon-thin {
        border-width: 1px;
    }
}

.icon-hover {
    &:hover {
        transform: scale(1.1);
    }
}

.icon-filled {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: #fff;
    font-size: 0.7rem;
    &-sm {
        width: 18px;
        height: 18px;
        font-size: 0.55rem;
    }
}

@each $color,
$value in $theme-colors {
    .icon-outlined-#{$color} {
        border-color: $value;
        color: $value;
    }
}


/*
     CONTENT BOXES
    ------------------------
*/
.box-image-text, .box-image {
    overflow: hidden;
    position: relative;

    &[class*='-overlay'] {
        &::after {
            opacity: 0;
        }
    }

    .overlay-content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &-heading {
        color: $white;
        transition: all 0.3s;
        transform: translateY(-1rem);
        opacity: 0;
    }

    &-content {
        transition: all 0.3s;
        transform: translateY(1rem);
        opacity: 0;
    }

    &-description {
        transition: all 0.3s;
        transform: translateY(1rem);
        opacity: 0;
    }

    &:hover {
        .box-image-text-heading, .box-image-text-content {
            opacity: 1;
            transform: none;
        }
        .box-image-text-description {
            opacity: 1;
            transform: none;
            transition-delay: 0.15s;
        }
        .box-image-text-content {
            transition-delay: 0.3s;
        }
        &::after {
            opacity: 0.6;
        }
    }
}

.box-image {
    [class*='-overlay'] {
        &::after {
            opacity: 0;
        }
    }
    &:hover {
        .box-image-content {
            opacity: 1;
            transform: none;
            transition-delay: 0s;
        }
        [class*='-overlay'] {
            &::after {
                opacity: 0.6;
            }
        }
    }
}

.block-icon-hover {
    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}


/*
     LINED HEADINGS
    ------------------------
*/
.lined {
    position: relative;
    padding-bottom: 0.7rem;
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 6.25rem;
        height: 2px;
        background: $primary;
    }

    &-center {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .lined#{$infix}-start {
            text-align: left;
            &::after {
                left: 0;
                transform: none;
            }
        }
        .lined#{$infix}-center {
            text-align: center;
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

    }
}


/*
     SOCIAL LINKS
    ------------------------
*/
.social-link {
    width: 28px;
    height: 28px;
    background: $primary;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 2px;
    font-size: 0.8rem;

    &:hover, &:focus {
        transform: scale(1.1);
        color: #fff;
        transition: all 0.3s;
        text-decoration: none;
    }

    &.facebook {
        background: #4561ae;
    }
    &.youtube {
        background: #c22026;
    }
    &.twitter {
        background: #34ccff;
    }
}

.social-link-hover {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    transition: all 0.3s;

    &:hover, &:focus {
        background: $primary;
        color: #fff;
        text-decoration: none;
    }
}


/*
     RIBBONS
    ------------------------
*/
.ribbon-holder {
    display: inline-block;
}

.ribbon {
    display: inline-block;
    color: #fff;
    position: relative;
    background: $primary;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0.3rem 1rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    font-weight: bold;

    &::before {
        content: '';
        border-top: 1rem solid darken($primary, 15%);
        border-left: 1rem solid transparent;
        border-right: 0 solid transparent;
        position: absolute;
        bottom: -1rem;
        left: 0;
        display: block;
    }

    &::after {
        content: '';
        display: block;
    }
}

@each $color,
$value in $theme-colors {
    .ribbon-#{$color} {
        background: $value,;
        &::before {
            border-top-color: darken($value, 15%);
        }
    }
}
